import ContactMixin from '~/mixins/ContactMixin'
import SocialNetworkLinks from '@theme/components/content/SocialNetworkLinks'

export default {
  mixins: [ContactMixin],
  components: {
    SocialNetworkLinks,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
  },
}
